import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "score": 1,
  "featured": true,
  "tileImage": "./tile-image-01.png",
  "tileTextColorScheme": "light",
  "types": ["system", "research"],
  "status": ["completed"],
  "title": "Spatial AR",
  "subtitle": "Interaction Design using Spatial AR with a Projector-Vision System",
  "description": "",
  "years": ["2017", "2018"],
  "institutions": ["tohoku", "icd lab", "riec"],
  "location": "japan",
  "keywords": {
    "topics": ["ar", "projection", "spatial", "natural hmi", "hmi", "computer vision"],
    "tools": ["unity", "C#", "optitrack camera system", "tipron robot"]
  },
  "people": [{
    "name": "Niklas Buhl",
    "role": "Designer"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* # Spatial Augmented Reality */
    }
    <h2>{`Description`}</h2>
    <p>{`Projector system.
Projection of an interface onto physical moving objects for various future applications.
Codename: Neon
IR Camera array for motion tracking
Projector robot Tipron
Using a projector-robot, CV and Unity I built a system to create an AR experience, intended for interactive interaction with physical objects without display or glasses.
Using a projector-robot, CV and Unity I built a system to create an AR experience, intended for interactive interaction with physical objects without display or glasses.
ICD Lab, RIEC, Tohoku University
Mobile Robot Projector-Vision System for Natural Interaction and Spatial Augmented Reality
Using a moving 2D projector in a 3D environment.
Projecting in a 3D environment
Surface as display
Human-Robot Collaboration (HRC)
Human-Robot Interaction (HRI)
Human-Computer Interaction (HCI)`}</p>
    <h2>{`Vision Projector`}</h2>
    <h2>{`Activities`}</h2>
    <ul>
      <li parentName="ul">{`Design`}</li>
      <li parentName="ul">{`C# programming in Unity`}</li>
      <li parentName="ul">{`Create Environment in Unity`}</li>
      <li parentName="ul">{`Create Neon(Tipron) in Unity`}</li>
      <li parentName="ul">{`Computer Vision`}</li>
      <li parentName="ul">{`3D reading of what it is looking at`}</li>
      <li parentName="ul">{`Geometric Projection`}</li>
    </ul>
    <h2>{`Mentionable Results`}</h2>
    <h2>{`Links / References`}</h2>
    <ul>
      <li parentName="ul">{`Report`}</li>
      <li parentName="ul">{`Presentation`}</li>
      <li parentName="ul">{`Paper`}</li>
      <li parentName="ul">{`Poster`}</li>
    </ul>
    <p>{`Human-Computer Interaction
Human-Robot Interaction
Mobile`}</p>
    <h2>{`Hardware`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.optitrack.com/"
        }}>{`OptiTrack System`}</a></li>
      <li parentName="ul">{`Tipron`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      